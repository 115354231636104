module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Green Flamingo","short_name":"GreenFlamingo","start_url":"/","background_color":"#8CA668","theme_color":"#8CA668","display":"minimal-ui","icon":"src/images/plavi-krug-fav.jpg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"1e8177825c7207163068bc01e783ceda"},
    },{
      plugin: require('../plugins/berry-plugin/gatsby-browser.js'),
      options: {"plugins":[],"webFrontId":"HSANUF4E2X5KUK763H9YAU9N96HGQCLMRV0J4MTU"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"custom":{"families":["Calibri"],"urls":["/fonts/fonts.css"]}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
