/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */


// import React from "react"
// import { GlobalProvider} from "./src/context/GlobalContext";
// import * as Survey from "survey-react";

import "./src/css/wow_book.css"
